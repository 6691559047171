<template>
  <div>
    <!-- <ListItemMeta :title="article.title.rendered" :description="localDateText"/> -->
    <!-- <span v-html="article.content.rendered"/>" -->
    <div class="cell-header">
      <div class="cell-title">{{article.title.rendered}}</div>
      <div class="cell-datetime">{{localDateText}}</div>
    </div>
    <div class="cell-subtitle" v-html="article.excerpt.rendered"></div>
    <div class="cell-footer">
      <a class="cell-read-more" @click="$_onDetailClicked">
        <font-awesome-icon :icon="['fas', 'angle-right']"/> 全文
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleCell',
  props: {
    article: {type: Object, required: true},
    index: {type: Number, required: true}
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    $_onDetailClicked: function() {
      this.$router.push('/blogs/' + this.article.id)
      // this.$emit('onSelect', this.article.id, this.index)
    }
  },
  computed: {
    localDateText: function() {
      var localDateText = new Date(this.article.date).toLocaleString()
      return localDateText
    },
  }
}
</script>

<style scoped lang="less">
@import '../../theme/darkzero.less';
.cell-header {
  display: inline-block;
  width: 100%;
  .cell-title:extend(.title-list) {
    line-height: 1.2rem;
    float: left;
    position: relative;
    max-width: 70%;
  }
  .cell-datetime {
    font-size: 0.8rem;
    line-height: 1.2rem;
    float: right;
    color: @text-color-secondary;
  }
}
.cell-subtitle:extend(.subtitle-list) {
  font-size: 0.9rem;
  display: block;
}
.cell-footer {
  display: inline-block;
  width: 100%;
  .cell-read-more {
    font-size: 0.9rem;
    float: right;
  }
  .cell-read-more::after {
    content: "...";
  }
}
</style>