<template>
  <div id="HomeArticlePanel" class="home-panel">
    <div class="title title-left title-top-space h1">
      <router-link to="/blogs">
        <strong>B</strong>logs
      </router-link>
    </div>
    <div class="content content-block content-bg corner-radius-all" :class="{hidden: isLoading, entry: !isLoading }" :loading="isLoading" dis-hover>
      <div v-for="(article, idx) in articles"
        :key="article.id">
        <hr v-if="idx!=0"/>
        <ArticleCell
          :index="idx"
          :article="article"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import homeManager from '../manager/HomeManager.js'
import ArticleCell from '../blog/ArticleCell'
export default {
  name: 'HomeArticlePanel',
  components: {
    ArticleCell
  },
  setup() {
    const isLoading = ref(true)
    let articles = ref([])
    
    homeManager.getArticleListThumbnail((ret, obj) => {
      if (ret) {
        articles.value = obj
        //console.log(articles.value)
      }
      else {
        console.warn('Can not get data')
      }
      isLoading.value = false
    })
    return {
      isLoading,
      articles
    }
    
  }
}
</script>
