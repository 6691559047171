import Axios from 'axios'
class HomeManager {
  BlogApiBaseUrl = ''
  // https://darkzero.me/blog/wp-json/wp/v2/

  getAboutMe(callback) {
    const apiUrl = 'https://darkzero.me/blog/wp-json/wp/v2/pages/27'
    Axios.get(apiUrl).then(response => {
      callback(true, response.data)
    })
    .catch(error => {
      console.error(error)
      callback(false, null)
    })
    .finally (() => {
    })
  }
  
  // get Article List for home page
  getArticleListThumbnail(callback) {
    const apiUrl = 'https://darkzero.me/blog/wp-json/wp/v2/posts?context=embed&per_page=3&page=1'
    Axios.get(apiUrl).then(response => {
      callback(true, response.data)
    })
    .catch(error => {
      callback(false, error.response)
    }).finally(() => {
      //
    })
  }

  // get photo list for home page
  getPhotoListThumbnail(callback) {
    const apiUrl = 'https://darkzero.me/blog/wp-json/wp/v2/photos?per_page=6'
    //const defaultImageUrl = require('../assets/project_default.jpg')
    const pageUrlBase = 'https://darkzero.me/blogs/?page_id='
    let photos = []
    Axios.get(apiUrl).then(response => {
      response.data.forEach(item => {
        //console.log(item)
        let photo = {
          'title' : item.title.rendered,
          'thumbnail' : item.photo_thumbnail,
          'description' : item.photo_description,
          'date' : item.photo_date,
          'image_url' : '',
          'page_url': pageUrlBase+item.id
        }
        if (item.photo_image != undefined) {
          photo['image'] = item.photo_image
        }
        photos.push(photo)
      })
      callback(true, photos)
    }).catch(error => {
      callback(false, error.response)
    }).finally(() => {
      //
    })

  }
}

const homeManager = new HomeManager()
export default homeManager
