<template>
  <div id='DZHeader' class="page-header corner-radius-bottom-left corner-radius-bottom-right">
    <div class="logo">LOGO</div>
    <div class="title"><router-link to="/">{{title}}</router-link></div>
    <div class="menu">
      <router-link v-for="(item) in menuItems" :to="item.url" class="menu-item">
        <font-awesome-icon :icon="item.icon"/> {{item.text}}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DZHeader',
  data () {
    return {
      title: 'Darkzero',
      menuItems: [
        // {
        //   text: 'Home',
        //   id: 'home',
        //   url: '/',
        //   icon: 'ios-home',
        // },
        {
          text: 'Works',
          id: 'works',
          url: '/works',
          icon: ['fas', 'tools'],
        },
        {
          text: 'Blog',
          id: 'blog',
          url: '/blogs',
          icon: ['fas', 'bookmark'],
        },
        {
          text: 'About',
          id: 'about',
          url: '/about',
          icon: ['fas', 'info-circle'],
        }
      ]
    }
  },
  methods: {
    $_openPage: function (name) {
      console.log('click: ' + name)
      var url = this.menuUrl[name]
      this.$router.push(url)
    },
    $_openSubMenu: function (name) {
      console.log('click: ' + name)
      var url = this.menuUrl[name]
      this.$router.push(url)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../theme/darkzero.less';
div.page-header {
  background-color: #ffffff;
  width: 100%;
  max-width: 900px;
  z-index: 999;
  margin: auto;
  height: 100%;
  font-family: @font-header;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  div.logo {
    width: 40px;
    height: 100%;
    border-radius: 0px;
    float: left;
    position: relative;
    top: 0px;
    left: 20px;
    // logo image
    img {
      display: inline-block;
      width: 64px;
      height: 100%;
    }
  }
  div.title {
    height: 40px;
    line-height: 40px;
    font-size: @font-size-big;
    font-weight: bold;
    text-align: center;
    color: @title-color;
    //background: #f95;
    border-radius: 8px;
    float: left;
    position: relative;
    top: 10px;
    left: 30px;
    padding: 0px 20px 0px 20px
  }
  div.menu {
    position: relative;
    // display: inline-block;
    float: right;
    //background: #095;
    left:auto;
    // right: 1rem;
    height: 40px;
    line-height: 40px;
    padding: 0px;
    vertical-align: middle;
    top: 10px;
    right: 1rem;
    .menu-item {
      width: 80px;
      margin: 0 auto;
      margin-right: 1.5rem;
      //background: #f95;
      text-align: center;
      font-size: 14px;
    }
  }
}
@media (max-width: 700px) {
  div.page-header {
    div.menu {
      display: none;
    }
  }
}
</style>
