// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import Vue from 'vue'
import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/lib/theme-chalk/display.css';
import App from './App'
import router from './router'
import './theme/darkzero.less'
import './assets/css/code.css'
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub, faWeibo, faTwitter, faFlickr, faInstagram, faFacebook, faAppStoreIos, faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { faHome, faTools, faBookmark, faInfoCircle, faList, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faGithub, faWeibo, faTwitter, faFlickr, faInstagram, faFacebook, faAppStoreIos, faApple, faGooglePlay)
library.add(faHome, faTools, faBookmark, faInfoCircle, faList, faAngleRight)
library.add(faCalendarAlt)
//Vue.component('font-awesome-icon', FontAwesomeIcon)

const app = createApp(App)
app.use(router)
// app.use(store)
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(ElementPlus)
app.mount('#app')

app.config.productionTip = false
