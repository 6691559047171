// Router index
import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router'

// Home
const Home = () => import(/* webpackChunkName: "Home" */ '../components/Home')

// About Me
const About = () => import(/* webpackChunkName: "About" */ '../components/About')

// Photos
const Photos = () => import(/* webpackChunkName: "Photos" */ '../components/Photos')

// Works
const Works = () => import(/* webpackChunkName: "Works" */ '../components/Works')
const WorksMain = () => import(/* webpackChunkName: "Works" */ '../components/works/WorksMain')
const Application = () => import(/* webpackChunkName: "Works" */ '../components/works/App')
const Illustrate = () => import(/* webpackChunkName: "Works" */ '../components/works/Illustrate')
const Other = () => import(/* webpackChunkName: "Works" */ '../components/works/Other')

// Blog
const BlogMain = () => import(/* webpackChunkName: "Blog" */ '../components/blog/BlogMain')
const BlogList = () => import(/* webpackChunkName: "Blog" */ '../components/blog/BlogList')
const BlogArticle = () => import(/* webpackChunkName: "Blog" */ '../components/blog/BlogArticle')

// Agora Test
const AgoraTest = () => import(/* webpackChunkName: "Agora" */ '../components/agoraTest/AgoraTestPage')

const routerHistory = createWebHashHistory() //createWebHistory()
const router = createRouter({
  history: routerHistory,
  //mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/works',
      name: 'Works',
      component: Works,
      children: [
        // works main
        {path: '/works', component: WorksMain},
        // app
        {path: '/works/app', component: Application},
        // photo
        // {path: '/works/photo', component: Photo},
        // illustrate
        {path: '/works/illustrate', component: Illustrate},
        // other
        {path: '/works/other', component: Other}
      ]
    },
    {
      path: '/photos',
      name: 'Photos',
      component: Photos
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/blogs',
      name: 'BlogMain',
      component: BlogMain,
      children: [
        {path: '/blogs', component: BlogList},
        {path: '/blogs/:post_id', component: BlogArticle}
      ]
    },
    {
      path: '/agoraTest',
      name: 'AgoraTest',
      component: AgoraTest
    }
  ]
})

export default router
