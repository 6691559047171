<template>
  <div id="HomePhotoPanel" class="home-panel">
    <div class="title title-right title-top-space">
      <strong>P</strong>hotos
    </div>
    <el-row :gutter="16" class="content content-photo corner-radius-all" type="flex" justify="space-around" style="flex-wrap:wrap" :class="{hidden: isLoading, entry: !isLoading}">
      <!-- <Spin size="large" fix v-if="isLoading"></Spin> -->
      <el-col v-for="(photo, index) in photos" class="photo-cell"
        :xs="{span:24,offset:0}"
        :sm="{span:12,offset:0}"
        :md="{span:12,offset:0}"
        :lg="{span:12,offset:0}">
        <PhotoCell :photo="photo"/>
      </el-col>
    </el-row>
    <!--
    <router-link :to="this.router_link">
      <span class="read-more">rEAd mORe &gt;</span>
    </router-link>
    -->
  </div>
</template>

<script>
import { ref } from 'vue'
import PhotoCell from '../photos/PhotoCell.vue'
import homeManager from '../manager/HomeManager.js'
export default {
  name: 'HomePhotoPanel',
  components: {
    PhotoCell
  },
  setup() {
    const isLoading = ref(true)
    let photos = ref([])
    
    homeManager.getPhotoListThumbnail((ret, obj) => {
      if (ret) {
        photos.value = obj
        //console.log(photos.value)
      }
      else {
        console.warn('Can not get data')
      }
      isLoading.value = false
    })
    return {
      isLoading,
      photos
    }
  }
}
</script>

<style scoped lang='less'>
/* only for photo */
.photo-cell {
  display: inline-block;
  position: relative;
  border-radius: 1.8rem;
}
@media (max-width: 768px) {
  .photo-cell{
    margin: 12px;
  }
}
</style>
