<template>
  <div class="left_panel round-corner">
    <div  class="avatar-image">
      <img :src="this.avatarImageUrl"/>
    </div>
    <div class="avatar-info">
      <p class="nickName">{{nickName}}</p>
      <p class="shortInfo">{{shortInfo}}</p>
      <div class="blog-panel">
        <!-- <Button ref="DivButton" shape="circle" size="large" target="_blank" icon="logo-wordpress"
          class="blog_button"
          @click="showButtonEffect">
          Blog
          <span ref="EffectSpan" class="buttonEffect" :class="{animate: this.buttonEffect}"></span>
        </Button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    isThumbnail: Boolean
  },
  data() {
    return {
      avatarImageUrl: require("../assets/avatar.jpg"),
      nickName: 'darkzero',
      shortInfo: '半吊子且懒惰成性的码农\n\nどんなに怖くたって\n目を逸らせないよ\n全ての終わりに\n愛があるなら',
      buttonEffect: false
    }
  },
  mounted() {
      let ripple = this.$refs.EffectSpan
      //console.log(ripple)
  },
  methods: {
    showButtonEffect: function(e) {
      // console.log(this.$refs.DivButton)
      this.buttonEffect = true
      // let btn = e.target
      let ripple = this.$refs.EffectSpan
      console.log(ripple)
      if (ripple) {
        console.log("aaa")
        let d = Math.max(ripple.offsetHeight, ripple.offsetWidth)
        let x = e.layerX - ripple.offsetWidth / 2
        let y = e.layerY - ripple.offsetHeight / 2
        ripple.setAttribute('style', 'height: ' + d + 'px; width: ' + d + 'px; top: ' + y + 'px; left: ' + x + 'px;')
      }
      this.$nextTick(() => {
        setTimeout(() => {
          //this.buttonEffect = false
          console.log("bbb")
        }, 1000)
      })
      // to="https://darkzero.me/blog"
    }
  }
}
</script>

<style scoped>
.left_panel {
  background: #fff;
  margin-top: 16px;
}
.avatar-div {
  width: 100%;
  text-align: center;
  padding: 1rem;
}
.avatar-image {
  display: inline-block;
  position: relative;
  text-align: center;
  /* background-color: red; */
}
.avatar-image img {
  height: 130px;
  width: 130px;
  border-radius: 200px;
  border: 6px solid #dddddd;
  background-clip: padding-box;
  margin: 32px 0 0 5px;
  object-fit: cover;
}
.avatar-info {
  text-align: center;
  /* background-color: blue; */
}
.avatar-info p.nickName {
  font-size: 1.6rem;
  font-weight: 900;
  /* color: #3A8FB7; */
  font-family: 'SF Mono', Menlo, 'Courier New', monospace;
}
.avatar-info p.shortInfo {
  display: inline;
  font-size: 0.9rem;
  font-weight: 200;
  white-space: pre-line;
}
.blog-panel {
  padding-top: 1rem;
  padding-bottom: 2rem;
  /* font-family: monospace; */
}
.blog_button {
  background: #ff6633;
}
.blog_button:hover {
  background: #dddddd;
}
.buttonEffect {
  display: block; 
  position: absolute;
  background: hsla(0, 0%, 65%, 0.66);
  border-radius: 100%;
  transform: scale(0);
}
span.animate {
  animation: ani 1.0s linear;
}
@keyframes ani {
  100% {opacity: 0; transform: scale(2.5);}
}
</style>
