<template>
  <!-- display in home page -->
  <div id="About" class="home-panel">
    <div class="title title-right title-top-space">
      <router-link to="/about">About <strong>Me</strong></router-link>
    </div>
    <div class="content content-block content-bg corner-radius-all" :class="{hidden: isLoading, entry: !isLoading}">
      <span v-html="content"/>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import homeManager from '../manager/HomeManager'
export default {
  name: 'HomeAboutPanel',
  props: {
  },
  setup(props, context) {
    const content = ref('')
    const isLoading = ref(true)
    homeManager.getAboutMe((ret, obj) => {
      if (ret) {
        content.value = obj.content.rendered
        //console.log(content)
      }
      else {
        console.warn('Can not get data')
      }
      isLoading.value = false
    })
    return {
      isLoading,
      content
    }
  },
  methods: {
  }
}
</script>
