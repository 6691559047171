<template>
  <div class="photo-block">
    <a :href="photo.page_url" target="_blank" @mouseover="isHover=true" @mouseleave="isHover=false">
      <img :src="photo.image_url"/>
      <div class="photo-info" :class="{'photo-info-hidden': !isHover, 'photo-info-show': isHover}">
        <p class="photo-title">{{photo.title}}</p>
        <p class="photo-description">{{photo.description}}</p>
      </div>
    </a>
  </div>
</template>

<script>
import Axios from 'axios'
import { onMounted, ref } from 'vue'
export default {
  name: 'PhotoCell',
  props: {
    photo: Object
  },
  setup(props) {
    // values
    const isHover = ref(false)
    const mediaUrl = 'https://darkzero.me/blog/wp-json/wp/v2/media/'
    // functions
    // get photo thumb image
    function getPhotoImage(obj) {
      console.log(obj)
      if (obj.thumbnail != undefined) {
        Axios.get(mediaUrl+obj.thumbnail)
        .then(response => {
          let data = response.data
          //console.log(data)
          obj.image_url = data.media_details.sizes.medium.source_url
        })
      }
    }

    onMounted(() => {
      getPhotoImage(props.photo)
    })

    return {
      //mediaUrl,
      isHover,
      getPhotoImage
    }
  }
}
</script>

<style scoped lang='less'>
.photo-block {
  width: 100%;
  height: 100%;
  min-height: 160px;
  //background-color: #f00;
}
.photo-block img {
  display: block;
  object-fit: cover;
  margin: auto;
  height: 100%;
  width: 100%;
  //min-height: 160px;
  background-clip: padding-box;
  background-color: #ddd;
  border-radius: 0.8rem;
}
/* Photo info text */
.photo-info {
  display: inline-block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 8px 0px 8px;
  padding-top: 0.1rem;
  padding-bottom: 0.2rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;

  &-hidden {
    opacity: 0;
    transform: translateY(30px) scale(1, 1);
    // transition: opacity 0.75s ease-out 10.3s, transform 0.5s ease-out 10.3s;
  }
  &-show {
    opacity: 1;
    transform: translateY(0) scale(1, 1);
    transition: opacity 0.3s ease-out 0.1s,
                transform 0.3s ease-out 0.02s;
  }
}
.photo-info p {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.photo-info p.photo-title {
  font-size: 1.0rem;
  font-weight: 800;
  color: #181818;
}
.photo-info p.photo-description {
  font-size: 0.8rem;
  font-weight: 300;
  color: #252525;
}
</style>