<template>
  <div id="app">
    <el-container class="layout">
      <el-header class="app-header">
        <DZHeader/>
      </el-header>
      <el-main :style="{margin: '0px 0px 0', minHeight: '500px'}" class="main-content">
        <router-view/>
      </el-main>
      <el-footer class="layout_footer">
        <EggFooter/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import DZHeader from './components/common/Header'
import EggFooter from './components/common/Footer'
import Home from './components/Home'
export default {
  name: 'App',
  components: {
    DZHeader,
    EggFooter,
    Home
  },
  data() {
    return {
      showHeader: false
    }
  },
  mounted() {
    // eventBus.$on("DZ_ShowHeader", (flag) => {
    //   console.log("DZ_ShowHeader:" + flag)
    //   this.showHeader = flag
    // })
  },
  beforeUnmount() {
    // console.log("DZ_ShowHeader: beforeDestroy")
    // eventBus.$off("DZ_ShowHeader")
  }
}
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  margin-top: 0px;
  height: 100%;
  background-color: #f1f1f1;
  padding-bottom: 32px;
}
/* html {
  //background-color: #224d75;
} */
body {
  background-color: inherit;
  margin: 0px;
}
</style>

<style scoped>
.main-content {
  margin: 0px 0px 0;
  min-height: 500px;
  display: block;
  /* background: #f98; */
}
</style>
