<template>
  <div class="home">
    <el-row align="top">
      <el-col
        :xs="{span: 24, offset: 0}"
        :sm="{span: 7, offset: 0}"
        :md="{span: 7, offset: 0}"
        :lg="{span: 7, offset: 0}">
        <Avatar ref="AvatarPanel" :class="{hidden: !isLoaded, entry: isLoaded}"/>
      </el-col>
      <el-col
        :xs="{span:24,offset:0}"
        :sm="{span:16,offset:1}"
        :md="{span:16,offset:1}"
        :lg="{span:16,offset:1}">
        <HomeAboutPanel/>
        <HomeArticlePanel/>
        <HomePhotoPanel/>
      </el-col>
    </el-row>
  </div> 
</template>

<script>
import { ref, onMounted } from 'vue'
// component
import Avatar from './Avatar'
import HomeAboutPanel from './home/HomeAboutPanel'
import HomeArticlePanel from './home/HomeArticlePanel'
import HomePhotoPanel from './home/HomePhotoPanel'
// js
import homeManager from './manager/HomeManager.js'
export default {
  name: 'Home',
  components: {
    Avatar,
    HomeAboutPanel,
    HomeArticlePanel,
    HomePhotoPanel
  },
  setup() {
    const isLoaded = ref(false)
    // life cycle
    onMounted(() => {
      if (!isLoaded.value) {
        setTimeout(() => {
          isLoaded.value = true
        }, 100)
      }
    })
    //
    return {
      isLoaded
    }
  }
}
</script>

// serif (衬线体)
// sans-serif(无衬线体)
// monospace(等宽体) 
// ursive(草书体)
// fantasy(幻想体)
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../theme/darkzero.less';
.home {
  width: 100%;
  max-width: 900px;
  margin-top: 60px;
  margin-bottom: 40px;
  margin: auto;
}
@media (max-width: 600px) {
  .home {
    width: 100%;
  }
}
.hidden {
  opacity: 0;
  transform: translateX(-100px) scale(1, 1);
  // transition: opacity 0.75s ease-out 10.3s, transform 0.5s ease-out 10.3s;
}
.entry {
  opacity: 1;
  transform: translateX(0) scale(1, 1);
  transition: opacity 0.75s ease-out 0.5s,
              transform 0.75s ease-out 0.5s;
}
.entry:hover {
  transform: 1s translate(50px,50px)
}
</style>
