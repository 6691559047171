<template>
  <div class="page-header corner-radius-bottom-left corner-radius-all">
    <el-row id='EggFooter'>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" id="sns_panel" class="sns_panel">
        <a v-for="(sns, idx) in this.snsItems" :href="sns.url" :title="sns.name" target="_blank">
          <font-awesome-icon :icon="sns.icon" class="icon alt" />
        </a>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" class="footer_menu_panel">
        <div v-for="(menu_row, index) in bottom_menu" :key="index" class="footer_menu" >
          <router-link v-if="menu_row.router!=''" :to="menu_row.url">
            {{menu_row.title}}
          </router-link>
          <a v-else :href="menu_row.url" target="_blank">
            {{menu_row.title}}
          </a>
        </div>
      </el-col>
    </el-row>
    <br/>
    <el-row id='authorDiv'>
      <el-col :span='24' class="footer_info_col">copyright &copy; {{date}}</el-col>
      <el-col :span='24' class="footer_info_col">made by : {{author}}</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'EggFooter',
  data () {
    return {
      snsItems: [
        // { //facebook
        //   'name': 'Facebook',
        //   'url': 'https://www.facebook.com/darkzeromk2',
        //   'icon': ['fab', 'facebook']
        // },
        { // Twitter
          'name': 'Twitter',
          'url': 'https://twitter.com/darkzero_mk2',
          'icon': ['fab', 'twitter']
        },
        { // Instagram
          'name': 'Instagram',
          'url': 'https://www.instagram.com/darkzeromk2',
          'icon': ['fab', 'instagram']
        },
        { // Flickr
          'name': 'Flickr',
          'url': 'https://www.flickr.com/darkzero_mk2',
          'icon': ['fab', 'flickr']
        },
        { // Weibo
          'name': 'Weibo',
          'url': 'https://weibo.com/darkzero',
          'icon': ['fab', 'weibo']
        },
        { // Github
          'name': 'GitHub',
          'url': 'https://github.com/darkzero',
          'icon': ['fab', 'github']
        }
      ],
      bottom_menu: [
        {
          title: 'Home',
          url: '/',
          router: true
        },{
          title: 'Works',
          url: '/Works',
          router: true
        },{
          title: 'Photos',
          url: '/Photos',
          router: true
        },{
          title: 'About Me',
          url: '/About',
          router: true
        }
        // ,{
        //   title: 'Illustrations',
        //   //url: '/Illustrations',
        //   router: true
        // },
      ],
      author: 'darkzero',
      date: '2019 - 2020'
    }
  }
}
</script>

<style scoped lang="less">
@import '../../theme/darkzero.less';
div.page-header {
  background-color: transparent;
  width: 100%;
  max-width: 900px;
  z-index: 999;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  font-family: @font-header;
  div.sns_panel {
    color: #fff;
    font-size: 22px;
    text-align: center;
  }
  div.sns_panel a {
    padding: 10px;
  }
  //
  div.footer_info_col {
    font-size: 1rem;
    text-align: center;
    color: @text-color
  }
  //
  div.footer_menu_panel {
    text-align: center;
  }
  div.footer_menu {
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 10px;
  }
  div.footer_menu a {
    font-size: 1rem;
    text-decoration: none !important;
  }
  div.footer_menu a:before {
    content: ""
  }
  div.footer_menu a:after {
    content: ""
  }
  div.footer_menu a:hover {
    text-decoration: underline !important;
  }
}
</style>
